/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Card from '../../shared/ui/Card';
import { EmptyCard } from '../../shared/ui/EmptyCard';
import { Button } from '../../shared/ui/Button';
import Alert from '../../shared/ui/Alert';
import { InputCard } from '../../shared/ui/InputCard';
import {
  StatusResponse,
  TagsResult,
  TitleResponse,
} from '../../../common/types';
import useGenericMutation from '../../../CustomHooks/useMutation';
import useAlert from '../../../CustomHooks/useAlert';
import { ALERT_MESSAGES } from '../../../constants';
import { RootState } from '../../../redux/store';
import { Icons } from '../../../helpers/icons';
import { useAxiosInterceptors } from '../../../api/apiInstances';
import { useLoading } from '../../../CustomHooks/useLoading';

interface TitleGenerationCardProps {
  isTitleGenerated?: boolean;
  generatedTitles: TitleResponse | null;
  notifyAltCard?: () => void;
  generatedTags?: TagsResult;
  disableAllButtons?: boolean;
  disableAltGenerationButton?: boolean;
  setSelectedTitleIndex: (value: number) => void;
  selectedTitleIndex: number;
}

interface GeneratedProps {
  heading?: string;
  title?: string;
  label?: string;
  isBetter: boolean;
  listingId?: string | null;
  maxLength?: number;
  toggleIsBetter: () => void;
  handleTitleSave?: (title: string) => void;
  setIsTitleChanged?: (value: boolean) => void;
  selectedTitle: 'title1' | 'title2';
  setSelectedTitle: (value: 'title1' | 'title2') => void;
}

interface TitleGenerationCardChildProps
  extends TitleGenerationCardProps,
    GeneratedProps {
  isTitleAlreadyGenerated: {
    title1: boolean;
    title2: boolean;
  };
}

export const TitleCard: React.FC<GeneratedProps> = ({
  title,
  label = '',
  heading,
  isBetter,
  toggleIsBetter,
  handleTitleSave,
  maxLength,
}) => {
  const buttonClass = `${
    isBetter
      ? 'text-etsyPrimary border-etsyPrimary px-[5px] pl-2'
      : 'text-[#B3001F] border-red-500'
  } bg-white border min-w-24 min-h-8`;

  return (
    <div>
      <div className="flex justify-between mb-6 items-center">
        <div className="text-lg md:text-xl font-semibold leading-5 ">
          {heading}
        </div>
        <Button
          label={label}
          size="medium"
          className={buttonClass}
          onClick={toggleIsBetter}
          icon={<Icons.Check />}
          showIcon={isBetter}
        />
      </div>
      <div className="w-full mx-auto">
        <InputCard
          initialValue={title || ''}
          showSaveButton
          onSave={handleTitleSave}
          maxLength={maxLength}
        />
      </div>
    </div>
  );
};

const TitleGenerationCardChild: React.FC<TitleGenerationCardChildProps> = ({
  listingId,
  generatedTitles,
  isBetter,
  selectedTitle,
  setSelectedTitle,
  isTitleAlreadyGenerated,
  setSelectedTitleIndex,
}) => {
  const sessionID = useSelector((state: RootState) => state.session.sessionID);

  const [titles, setTitles] = useState<{
    title1: string | undefined;
    title2: string | undefined;
  }>({
    title1: undefined,
    title2: undefined,
  });

  const { mutateAsync: saveEditedTitle } = useGenericMutation<StatusResponse>(
    `/listing/${listingId}`,
    'PUT'
  );

  const {
    alert: saveTitleAlert,
    setAlert: setSaveTitleAlert,
    closeAlert,
  } = useAlert();

  useEffect(() => {
    if (generatedTitles) {
      setTitles({
        title1: generatedTitles.title[0] || '',
        title2: generatedTitles.title[1] || '',
      });
      setSelectedTitle(isBetter ? 'title1' : 'title2');
      setSelectedTitleIndex(isBetter ? 0 : 1);
    }
  }, [generatedTitles, isBetter]);

  const handleTitleSave = useCallback(
    async (value: string, key: 'title1' | 'title2') => {
      const newTitles = { ...titles, [key]: value };
      setTitles(newTitles);
      const payload = {
        title: [newTitles.title1, newTitles.title2],
        title_selection: selectedTitle === 'title1' ? 0 : 1,
      };

      try {
        await saveEditedTitle(payload);
        setSelectedTitleIndex(selectedTitle === 'title1' ? 0 : 1);
        setSaveTitleAlert({
          isSuccess: true,
          message: ALERT_MESSAGES.TITLE_SAVE_SUCCESS,
        });
      } catch (error: any) {
        const message = error.response?.data?.detail;
        setSaveTitleAlert({
          isSuccess: false,
          message: message || ALERT_MESSAGES.TITLE_GENERATION_FAILED,
        });
      }
    },
    [
      titles,
      saveEditedTitle,
      setSaveTitleAlert,
      sessionID,
      selectedTitle,
      setSelectedTitleIndex,
    ]
  );

  const handleTitlePick = useCallback(
    async (pick: 'title1' | 'title2') => {
      if (isTitleAlreadyGenerated.title1 && isTitleAlreadyGenerated.title2) {
        setSaveTitleAlert({
          isSuccess: false,
          message: 'You have already used free generation for both titles',
        });
        return;
      }
      if (selectedTitle === pick) {
        setSaveTitleAlert({
          isSuccess: false,
          message: ALERT_MESSAGES.TITLE_ALREADY_SELECTED(
            pick === 'title1' ? 'Title A' : 'Title B'
          ),
        });
        return;
      }

      const payload = {
        title_selection: pick === 'title1' ? 0 : 1,
      };

      try {
        await saveEditedTitle(payload);
        setSelectedTitle(pick);
        setSelectedTitleIndex(pick === 'title1' ? 0 : 1);
        setSaveTitleAlert({
          isSuccess: true,
          message: ALERT_MESSAGES.TITLE_PICK_SUCCESS(
            pick === 'title1' ? 'Title A' : 'Title B'
          ),
        });
      } catch (error: any) {
        const message = error.response?.data?.detail;
        setSaveTitleAlert({
          isSuccess: false,
          message: message || ALERT_MESSAGES.TITLE_PICK_FAILED,
        });
      }
    },
    [
      selectedTitle,
      setSaveTitleAlert,
      sessionID,
      isTitleAlreadyGenerated,
      titles,
    ]
  );

  return (
    <>
      {saveTitleAlert && (
        <Alert
          isSuccess={saveTitleAlert.isSuccess}
          message={saveTitleAlert.message}
          onClose={closeAlert}
        />
      )}
      <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 border-b border-gray-200 pb-10 mt-5">
        <div className="flex-1 items-stretch">
          <TitleCard
            heading="Which Title Do You Prefer?"
            label="Title A"
            title={titles.title1}
            isBetter={selectedTitle === 'title1'}
            toggleIsBetter={() => handleTitlePick('title1')}
            handleTitleSave={value => handleTitleSave(value, 'title1')}
            selectedTitle={selectedTitle}
            setSelectedTitle={setSelectedTitle}
          />
        </div>
        <div className="flex-1">
          <TitleCard
            label="Title B"
            title={titles.title2}
            isBetter={selectedTitle === 'title2'}
            toggleIsBetter={() => handleTitlePick('title2')}
            handleTitleSave={value => handleTitleSave(value, 'title2')}
            selectedTitle={selectedTitle}
            setSelectedTitle={setSelectedTitle}
          />
        </div>
      </div>
    </>
  );
};

const TitleGenerationCard: React.FC<TitleGenerationCardProps> = ({
  isTitleGenerated,
  generatedTitles,
  notifyAltCard = () => {},
  generatedTags,
  disableAllButtons,
  disableAltGenerationButton,
  setSelectedTitleIndex,
  selectedTitleIndex,
}) => {
  useAxiosInterceptors();
  const listingId = useSelector((state: RootState) => state.listing.listingId);
  const { CustomLoader, load } = useLoading();
  const [isBetter, setBetter] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isTitleAlreadyGenerated, setIsTitleAlreadyGenerated] = useState({
    title1: false,
    title2: false,
  });
  const [selectedTitle, setSelectedTitle] = useState<'title1' | 'title2'>(
    'title1'
  );

  useEffect(() => {
    if (generatedTitles?.title_selection === 0) {
      setBetter(true);
    }
  }, [generatedTitles]);

  const toggleIsBetter = useCallback(() => setBetter(prev => !prev), []);

  const handleGenerateAlt = () => {
    setLoading(true);
    notifyAltCard();
  };

  useEffect(() => {
    if ((generatedTags?.tags?.length || 0) > 0) {
      setLoading(false);
    }
  }, [generatedTags]);

  const renderLoadingWarning = () => {
    return (
      <div className="flex items-center justify-center rounded-md bg-[#176852] bg-opacity-15 text-[#176852] px-4 py-2 text-sm my-5">
        <Icons.GreenInfo className="w-5 h-5 mr-2" />
        <p>
          Please give this up to one minute to load and do not leave this page.
        </p>
      </div>
    );
  };

  return (
    <Card heading="Titles">
      {isTitleGenerated ? (
        <>
          <TitleGenerationCardChild
            generatedTitles={generatedTitles}
            isBetter={isBetter}
            toggleIsBetter={toggleIsBetter}
            listingId={listingId}
            selectedTitle={selectedTitle}
            setSelectedTitle={setSelectedTitle}
            isTitleAlreadyGenerated={isTitleAlreadyGenerated}
            setSelectedTitleIndex={setSelectedTitleIndex}
            selectedTitleIndex={selectedTitleIndex}
          />
          <div className="flex justify-start mt-10">
            {isLoading ? (
              <CustomLoader width={200} />
            ) : (
              <Button
                label="Generate AltText/Description/Tags"
                color="primary"
                size="large"
                fullWidth
                className={`md:max-w-80 ${
                  disableAltGenerationButton
                    ? 'bg-[#808080] text-[#B7B7B7]'
                    : 'text-white'
                }`}
                onClick={() => {
                  setIsTitleAlreadyGenerated(prev => ({
                    ...prev,
                    [selectedTitle]: true,
                  }));
                  load(24);
                  handleGenerateAlt();
                }}
                disabled={disableAltGenerationButton}
              />
            )}
          </div>
          {isLoading && <div>{renderLoadingWarning()}</div>}
        </>
      ) : (
        <EmptyCard isGeneration />
      )}
    </Card>
  );
};

export default TitleGenerationCard;
