/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useDropzone } from 'react-dropzone';
import heic2any from 'heic2any';

import { Icons } from '../../../helpers/icons';
import { Button } from '../../shared/ui/Button';
import useGenericMutation from '../../../CustomHooks/useMutation';
import { ChatBotResponse } from '../../../common/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ConfirmationModal from '../../../container/AccountDetails/ConfirmationModal';

interface DragAndDropProps {
  onImageDescription: (
    description: ChatBotResponse,
    imgBase64: string | null,
    isError?: boolean
  ) => void;
  onImageUpload: (flag: boolean) => void;
  imgBase64: string | null;
  // sessionImageDescription: string | null;
  disableAllButtons: boolean;
  isDuplicate: boolean;
}

export interface DragAndDropRef {
  resetDragAndDrop: () => void;
}

const DragAndDrop = forwardRef<DragAndDropRef, DragAndDropProps>(
  (
    {
      onImageDescription,
      onImageUpload,
      imgBase64,
      // sessionImageDescription,
      disableAllButtons,
      isDuplicate,
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [descriptionGenerated, setDescriptionGenerated] =
      useState<boolean>(false);
    const [showImageUploadModal, setShowImageUploadModal] =
      useState<boolean>(false);
    const [pendingFile, setPendingFile] = useState<File | null>(null);

    const etsyListingId = useSelector(
      (state: RootState) => state.listing.listingId
    );

    const { mutateAsync: getImageDescription } =
      useGenericMutation<ChatBotResponse>(`/chat/${etsyListingId}`, 'POST');

    const convertHeicToJpeg = async (file: File): Promise<File | null> => {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: 'image/jpeg',
        });

        if (Array.isArray(convertedBlob)) {
          return new File([convertedBlob[0]], 'converted.jpeg', {
            type: 'image/jpeg',
          });
        } else {
          return new File([convertedBlob], 'converted.jpeg', {
            type: 'image/jpeg',
          });
        }
      } catch (error) {
        console.error('Error converting HEIC image:', error);
        return null;
      }
    };

    useEffect(() => {
      if (imgBase64 && !preview) {
        if (imgBase64.startsWith('https')) {
          setPreview(imgBase64);
        } else if (imgBase64.startsWith('data:image')) {
          setPreview(imgBase64);
        } else {
          setPreview(`data:image/jpeg;base64,${imgBase64}`);
        }
        //NOTE: chec the type for sessionImageDescription
        // onImageDescription(imageDescription, imgBase64);
      }
    }, [imgBase64, preview]);

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
      let file = acceptedFiles[0];

      if (file.type === 'image/heic') {
        const convertedFile = await convertHeicToJpeg(file);
        if (!convertedFile) return;
        file = convertedFile;
      }

      setPendingFile(file);
      setShowImageUploadModal(true);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: {
        'image/webp': ['.webp'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
        'image/heic': ['.heic'],
        'image/gif': ['.gif'],
      },
      maxFiles: 1,
    });

    const handleBrowseClick = () => {
      if (inputRef.current) {
        inputRef.current.value = '';
        inputRef.current.click();
      }
    };

    const handleImageUpload = async (file: File) => {
      setIsLoading(true);
      setProgress(0);
      setDescriptionGenerated(false);

      if (file.type === 'image/heic') {
        const convertedFile = await convertHeicToJpeg(file);
        if (!convertedFile) {
          setIsLoading(false);
          return;
        }
        file = convertedFile;
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64data = reader.result as string;

        try {
          const interval = setInterval(() => {
            setProgress(prev => {
              if (prev < 100) {
                return prev + 1;
              } else {
                clearInterval(interval);
                return prev;
              }
            });
          }, 150);
          const response: ChatBotResponse = await getImageDescription({
            type: 'image',
            base_64_image: base64data.split(',')[1],
          });

          clearInterval(interval);
          setProgress(100);
          setIsLoading(false);
          setDescriptionGenerated(true);
          onImageDescription(response, base64data.split(',')[1], false);
        } catch (error: any) {
          onImageUpload(false);
          const errorMessage = error.response.data.message;
          setIsLoading(false);
          setProgress(0);
          onImageDescription(errorMessage, null, true);
        }
      };

      reader.onerror = () => {
        console.error('Error reading file');
        setIsLoading(false);
      };

      reader.readAsDataURL(file);
    };

    const handleConfirmUpload = () => {
      if (pendingFile) {
        const previewUrl = URL.createObjectURL(pendingFile);
        setPreview(previewUrl);
        onImageUpload(true);
        handleImageUpload(pendingFile);
      }
      setShowImageUploadModal(false);
      setPendingFile(null);
    };

    const handleCancelUpload = () => {
      setShowImageUploadModal(false);
      setPendingFile(null);
    };

    useImperativeHandle(ref, () => ({
      resetDragAndDrop() {
        setPreview(null);
        setIsLoading(false);
        setProgress(0);
        setDescriptionGenerated(false);
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      },
    }));

    return (
      <div className="w-full h-full items-center justify-center dashed-container relative rounded-lg bg-gray-50 bg-opacity-50">
        <div className="w-full h-full flex flex-col p-4">
          <h2 className="text-lg md:text-xl font-bold mb-4 border-b pb-4">
            Upload an Image of your Etsy Item
          </h2>
          <div
            {...getRootProps({
              onClick: event => {
                event.stopPropagation();
              },
            })}
            className="flex flex-col items-center max-w-full justify-between h-full space-y-4 mb-6 md:mb-0"
          >
            <input {...getInputProps()} ref={inputRef} />
            {preview ? (
              <>
                <div className="flex flex-row w-full justify-between">
                  <img
                    src={preview}
                    alt="Preview"
                    className="md:w-11/12 w-10/12 h-auto max-h-80 object-contain md:mb-0 mb-8"
                  />
                </div>
                {isLoading && (
                  <div className="w-full mb-3">
                    <div className="flex text-sm font-semibold text-gray-700 justify-between items-center">
                      <span className="mb-2">Preparing Description</span>
                      <span>{Math.round(progress)}%</span>
                    </div>
                    <div className="w-full rounded-full h-1">
                      <div
                        className="bg-etsyPrimary h-2.5 rounded-full"
                        style={{ width: `${progress}%` }}
                      />
                    </div>
                  </div>
                )}
                {descriptionGenerated && (
                  <div className="w-full py-1 text-sm bg-[#F3EDE9] rounded-md border border-etsyPrimary text-center">
                    <p className="text-gray-500">
                      Description Prepared Successfully,
                    </p>
                    <p className="text-gray-500">
                      Please check{' '}
                      <span className="text-etsyPrimary">Chatbot</span>
                    </p>
                  </div>
                )}
                {isDuplicate && (
                  <div className="w-full py-2 text-sm bg-[#C00000] bg-opacity-15 rounded-md  text-center flex flex-row items-center justify-center px-3 ">
                    <Icons.RedInfo className="w-5 h-5 mr-2 flex-shrink-0" />
                    <p className="text-[#C00000]">
                      You are unable to change the image on this listing because
                      it is a duplicate listing
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div className="flex flex-col items-center">
                <Icons.DragNDrop className="w-36 h-44 mb-4" />
                <p className="text-lg font-semibold text-gray-700 text-center">
                  {isDragActive
                    ? 'Drop the files here ...'
                    : 'Drag & Drop here or, click to browse'}
                </p>
                <p className="text-sm text-gray-500 mb-5 mt-2 text-center">
                  etSEO supports PNG, JPEG, GIF, WEBP, and HEIC file formats
                </p>
                <Button
                  label={'Browse'}
                  size="medium"
                  disabled={disableAllButtons}
                  className={`rounded-sm px-8  py-[7px] ${
                    disableAllButtons
                      ? 'bg-[#808080] text-[#B7B7B7]'
                      : 'bg-etsyPrimary text-white'
                  }`}
                  onClick={handleBrowseClick}
                />
              </div>
            )}
          </div>
        </div>
        <ConfirmationModal
          isOpen={showImageUploadModal}
          closeModal={handleCancelUpload}
          onCancel={handleCancelUpload}
          onConfirm={handleConfirmUpload}
          title="Are you sure this is the image you want to upload?"
          description="Once uploaded, it cannot be changed or replaced"
          confirmLabel="Upload"
          cancelLabel="Cancel"
          icon={'etSEOLogo.png'}
        />
      </div>
    );
  }
);

export default DragAndDrop;
