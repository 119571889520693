import React, { useState } from 'react';

export const useLoading = () => {
  const [progress, setProgress] = useState<number>(0);

  const load = (time: number) => {
    setProgress(0);

    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev < 100) {
          return prev + 0.4;
        } else {
          clearInterval(interval);
          return prev;
        }
      });
    }, time * 10);
  };

  const CustomLoader = ({ width }: { width: number | 'full' }) => (
    <div className="text-right text-etsyPrimary text-xs">
      {progress > 100 ? 100 : Math.floor(progress / 5) * 5}%
      <div
        style={{ width: `${typeof width === 'number' ? `${width}px` : width}` }}
        className={`rounded-full border-2 border-etsyPrimary p-1`}
      >
        <div
          className="bg-etsyPrimary h-2.5 rounded-full"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );

  return { CustomLoader, load };
};
