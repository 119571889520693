import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSignUp, useUser } from '@clerk/clerk-react';
import useGenericMutation from '../../../CustomHooks/useMutation';
import {
  axiosStripeInstance,
  useAxiosStripeInterceptors,
} from '../../../api/apiInstances';

const VerifyEmail: React.FC = () => {
  const { signUp } = useSignUp();
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState<string>('');

  useAxiosStripeInterceptors();

  const { mutateAsync: createMongoUser } = useGenericMutation<
    any,
    any,
    { email: string; firstName: string; lastName: string; createdAt: Date }
  >('/users/create', 'POST', axiosStripeInstance);
  useEffect(() => {
    const verifyEmail = async () => {
      const searchParams = new URLSearchParams(location.search);
      const clerkStatus = searchParams.get('__clerk_status');

      if (clerkStatus === 'expired') {
        setErrorMessage('Verification link has expired.');
        console.error('Verification link expired');

        return;
      } else if (clerkStatus === 'verified') {
        window.fbq('track', 'CompleteRegistration', {
          status: true,
          external_id: user?.id,
        });

        if (!user?.primaryEmailAddress?.emailAddress) {
          navigate('/login');
        }
        if (user?.primaryEmailAddress?.emailAddress) {
          try {
            await createMongoUser(
              {
                email: user?.primaryEmailAddress?.emailAddress!,
                firstName: user.firstName ?? '',
                lastName: user.lastName ?? '',
                createdAt: user.createdAt ?? new Date(),
              },
              {
                onSuccess: () => {
                  console.log('User created in MongoDB');
                },
                onError: (error: string) => {
                  console.error('Failed to create user in MongoDB:', error);
                },
              }
            );
            navigate('/profile');
          } catch (error) {
            console.error('Failed to create user in MongoDB:', error);
          }
        }
      }
    };
    verifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    signUp,
    location.search,
    navigate,
    user?.primaryEmailAddress?.emailAddress,
    createMongoUser,
  ]);

  console.log(errorMessage);

  return (
    <div className="content-container bg-[#F9F9F9]">
      <div className="flex flex-col items-center bg-white px-4 py-6 my-4 md:p-8 rounded-lg shadow-md mx-5 md:min-w-[500px] min-w-[300px] border border-[#C9CBCD]">
        {!errorMessage ? (
          <>
            <h2 className="text-[#3F3F3F] text-xl md:text-3xl font-bold md:mt-10 mb-1 tracking-wider">
              Verifying Email...
            </h2>
            <p className="text-[#3F3F3F] text-sm md:text-base mb-6 mt-4">
              Please wait while we verify your email and set up your account...
            </p>
          </>
        ) : (
          <>
            <h2 className="text-red-10 text-xl md:text-3xl font-bold md:mt-10 mb-1 tracking-wider">
              {errorMessage}
            </h2>
            <p className="text-[#3F3F3F] text-sm md:text-base mb-6 mt-4">
              Try signing up again.
            </p>
            <a
              href="/signup"
              className="text-etsyPrimary font-semibold underline cursor-pointer"
            >
              Sign up
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
