import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useClerk, useUser } from '@clerk/clerk-react';

import { Images } from '../../../../helpers/images';
import { capitalize } from '../../../../helpers/utils';
import { Icons } from '../../../../helpers/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../../redux/feature/AuthSlice';
import { setSessionID } from '../../../../redux/feature/SessionSlice';
import { RootState } from '../../../../redux/store';

interface SideBarProps {
  isMobile: boolean;
  onClose?: () => void;
}

interface MenuItem {
  path: string;
  label: string;
  id?: string;
}

interface MenuSection {
  label: string;
  items: MenuItem[];
}

const Sidebar = (props: SideBarProps) => {
  const { isMobile, onClose = () => {} } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const { signOut } = useClerk();
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const { targetId } = state || {};
  const historyEnabled = useSelector(
    (state: RootState) => state.historyEnable.historyEnabled
  );

  useEffect(() => {
    if (!targetId) return;
    const el = document.getElementById(targetId);
    if (el && targetId === 'billing-management' && !state?.fromScroll) {
      if (state?.from && state.from !== '/account-details') {
        setTimeout(() => {
          const elementPosition = el.getBoundingClientRect().top;
          const offset = 0;
          const offsetPosition = window.scrollY + elementPosition - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }, 1500);
      } else {
        const elementPosition = el.getBoundingClientRect().top;
        const offset = 0;
        const offsetPosition = window.scrollY + elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
    if (el && targetId === 'profile' && !state.fromScroll) {
      const elementPosition = el?.getBoundingClientRect().top;
      const offset = 100;
      const offsetPosition = window.scrollY + elementPosition! - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [targetId, state]);

  useEffect(() => {
    if (pathname !== '/account-details') return;
    const handleScroll = () => {
      if (window.scrollY <= 100) {
        navigate('.', {
          replace: true,
          state: {
            ...state,
            targetId: 'profile',
            fromScroll: true,
          },
        });
      } else {
        const billingElement = document.getElementById('billing-management');
        if (billingElement) {
          const elementPosition = billingElement.getBoundingClientRect().top;
          if (Math.abs(elementPosition) <= 200) {
            navigate('.', {
              replace: true,
              state: {
                ...state,
                targetId: 'billing-management',
                fromScroll: true,
              },
            });
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pathname, navigate, state]);

  const handleSignOut = async () => {
    dispatch(setUser(null));
    dispatch(setSessionID(null));
    await signOut();
  };

  const openAccountDetails = () => {
    onClose();
    navigate('/account-details');
  };

  useEffect(() => {
    document.body.style.overflow = isMobile ? (isMobile ? 'hidden' : '') : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMobile]);

  const isActive = (path: string, id?: string) => {
    if (id) {
      return location.pathname === path && targetId === id;
    }
    return location.pathname === path;
  };

  const listingManagement: MenuSection = {
    label: 'Listings Management',
    items: [
      { path: '/home', label: 'Add New Listings' },
      { path: '/session-history', label: 'View Listing History' },
    ],
  };
  const accountManagement: MenuSection = {
    label: 'Account Management',
    items: [
      { path: '/account-details', label: 'Profile', id: 'profile' },
      {
        path: '/account-details',
        label: 'Billing',
        id: 'billing-management',
      },
    ],
  };

  const other: MenuSection = {
    label: 'Other',
    items: [
      { path: '/faq', label: 'FAQ' },
      { path: 'https://etseo.com/contact', label: 'Support' },
    ],
  };

  const renderMenuItems = (props: MenuSection) => {
    return (
      <div className="flex flex-col mt-6 text-lg">
        <div className="font-medium text-base tracking-[1px] text-[#828282] px-3 pl-8 mb-2">
          {props.label}
        </div>
        {props.items.map(item => (
          <div
            key={item.label}
            onClick={() => {
              if (item.path.startsWith('http')) {
                window.open(item.path, '_blank');
              } else {
                if (item.id) {
                  navigate(item.path, {
                    state: {
                      targetId: item.id,
                      from: location.pathname,
                    },
                  });
                } else {
                  navigate(item.path);
                }
              }
            }}
            className={`cursor-pointer font-semibold py-2 px-3 pl-9 ${
              isActive(item?.path, item?.id)
                ? 'bg-[#FAE8DE] text-etsyPrimary border-r-4 border-etsyPrimary'
                : 'hover:bg-[#FAE8DE] hover:text-etsyPrimary'
            } ${item.path === '/session-history' && historyEnabled ? 'hiss' : ''}`}
          >
            {item.label}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={`fixed top-0 md:pb-32 pb-14 z-12 left-0 h-full md:z-0 z-[11] bg-white drop-shadow-md overflow-hidden pt-5 text-black ${
        isMobile ? 'w-full' : 'w-80 md:block hidden'
      }`}
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-row  md:justify-center justify-between items-center">
          <Link to="/home" onClick={onClose}>
            <img
              src={Images.headerLogo}
              alt="Logo"
              className="h-24 w-48 cursor-pointer"
            />
          </Link>
          {isMobile && (
            <div className="md:hidden cursor-pointer" onClick={onClose}>
              <Icons.Cross />
            </div>
          )}
        </div>
        <div className="mt-4">{renderMenuItems(listingManagement)} </div>
        {renderMenuItems(accountManagement)}
        {renderMenuItems(other)}
        <div className="flex flex-col justify-end mt-auto bg-[#F5F5F5] mx-4 p-3 rounded-lg">
          <div className="flex justify-between items-center cursor-pointer">
            <div
              className="flex items-center md:space-x-2 space-x-1"
              onClick={openAccountDetails}
            >
              <img
                src={user?.imageUrl}
                alt="User"
                className="md:h-11 md:mr-2 mr-1 rounded-full md:w-11 w-8 h-8"
              />
              <span className="text-black font-bold text:sm md:text-lg flex-1 overflow-hidden overflow-ellipsis">
                {capitalize(user?.fullName!)}
              </span>
            </div>
            <div
              className="flex border-l items-center border-l-gray h-full pl-4 cursor-pointer"
              onClick={handleSignOut}
            >
              <Icons.Signout className="self-center" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
