import React from 'react';

const Footer = () => {
  return (
    <div className="bg-gradient-to-r from-etsyPrimary to-[#1eb995] text-white font-bold text-sm py-4 w-full text-center z-10">
      <p>
        ©2024 Seller Success Academy, LLC. All rights reserved.
      </p>
      {/* <p className='text-xs mt-1'>
        The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.
      </p> */}
      <p className='text-[11px] mt-1 px-44 leading-5 font-normal'>
        etSEO generates listing suggestions to optimize your Etsy shop performance using AI-driven technology. While we strive for accuracy and compliance with Etsy's guidelines, it is your responsibility to review and audit all generated listings before publishing. We are not liable for any content that violates Etsy's policies or terms of service. By using etSEO, you acknowledge that you are responsible for ensuring your listings adhere to all applicable standards and regulations. For more information, please review <a href="https://www.etsy.com/legal/terms-of-use" className="underline hover:text-gray-200">Etsy's Terms of Use</a>
      </p>
    </div>
  );
};

export default Footer;
