/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useHomeState from '../../CustomHooks/useHomeState';
import Card from '../../components/shared/ui/Card';
import TitleGenerationCard from '../../components/Home/GenerationCard';
import AltCard from '../../components/Home/AltCard';
import ChatComponent from '../../components/Home/ChatBot';
import DragAndDrop from '../../components/Home/DragNDrop';
import { Button } from '../../components/shared/ui/Button';
import Alert from '../../components/shared/ui/Alert';
import Loader from '../../components/shared/layout/Loader';
import ConfirmationModal from '../AccountDetails/ConfirmationModal';
import BillingPlanModal from '../../components/AccountDetails/BillingPlansModal';
import { Icons } from '../../helpers/icons';
import { useUser } from '@clerk/clerk-react';
import { useLoading } from '../../CustomHooks/useLoading';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const {
    isKeywordTitleGenerateButtonEnabled,
    isTitleGenerated,
    disableButton,
    isAltCardGenerated,
    imageDescription,
    customInstructions,
    imgBase64,
    isImageUploaded,
    generatedData,
    generatedTitles,
    isLoading,
    alert,
    chatRef,
    dragAndDropRef,
    setCustomInstructions,
    handleImageDescription,
    notifyChatBot,
    handleChatEnd,
    handleGenerateGeneration,
    handleAltCardGeneration,
    closeAlert,
    isNewListing,
    loadingSession,
    createNewListing,
    generatedAltText,
    generatedAltDescription,
    generatedAltTags,
    generatedCustomKeywords,
    isImageError,
    listingConsumedModalOpened,
    setListingConsumedModalOpened,
    plansModalOpened,
    setPlansModalOpened,
    purchaseTrialModalOpened,
    setPurchaseTrialModalOpened,
    consumedTrialModalOpened,
    setConsumedTrialModalOpened,
    handleStartTrial,
    createNewListingDuringSession,
    isCheckingOut,
    disableAllButtons,
    listingName,
    isDuplicate,
    disableAltGenerationButton,
    listingNotCompleteModal,
    setListingNotCompleteModal,
    setBypassIncompleteListingCheck,
    remainingListings,
    loadingRemainingListings,
    loadedChatMessages,
    isNewListingLoading,
    showScrollToBottomButton,
    scrollToTop,
    setSelectedTitleIndex,
    selectedTitleIndex,
    isFeedbackModalOpen,
    setIsFeedbackModalOpen,
    handleFeedback,
    isFeedBackAllowed,
    isDuplicationAllowed,
    fetchDuplicateSessionData,
    reGenerateLoading,
    setReGenerateLoading,
  } = useHomeState();

  const listingId = useSelector((state: RootState) => state.listing.listingId);
  const { user } = useUser();
  const userId = user?.id;
  const { CustomLoader, load } = useLoading();
  const navigate = useNavigate();
  useEffect(() => {
    if (window.pendo) {
      window.pendo.track('Home Page View');
      window.pendo.identify({
        visitor: {
          id: userId,
          email: user?.primaryEmailAddress?.emailAddress,
          full_name: `${user?.firstName} ${user?.firstName}`,
        },
        account: {
          id: user?.primaryEmailAddress?.emailAddress,
        },
      });
    }
  }, []);

  const titleLoadingDisclaimer = `Please give this up to one minute to load and do not leave this page.`;
  const remainingListingsDisclaimer = (
    <>
      {loadingRemainingListings ? (
        <div className="items-center justify-center flex flex-1 max-w-[500px] min-w-[300px]">
          <Loader />
        </div>
      ) : remainingListings > 0 ? (
        <>
          You currently have <b>{remainingListings}</b> listing(s) available. If
          you proceed with creating a new listing, you will have{' '}
          <b>{remainingListings - 1}</b> listing(s) remaining. Please ensure you
          have enough listings available before continuing.
        </>
      ) : (
        <>You have no listings available. Please purchase a plan to continue.</>
      )}
    </>
  );

  const renderDisclaimer = (disclaimer: string | React.ReactNode) => {
    return (
      <div className="flex items-center justify-center rounded-md bg-[#176852] bg-opacity-15 text-[#176852] px-4 py-2 text-sm my-5">
        <Icons.GreenInfo className="self-start w-5 h-5 mr-2 flex-shrink-0" />
        <div>{disclaimer}</div>
      </div>
    );
  };

  return (
    <>
      {loadingSession || reGenerateLoading ? (
        <div className="items-center justify-center flex flex-1">
          <Loader />
        </div>
      ) : (
        <div className="min-w-full md:py-4 py-4">
          {alert && (
            <Alert
              isSuccess={alert.isSuccess}
              message={alert.message}
              onClose={closeAlert}
            />
          )}
          {isNewListing ? (
            <Card
              showNameField={true}
              listingName={listingName}
              heading="Add New Listing 1"
              subHeading="Talk with Chatbot or Upload Image to Generate the Title"
              selection={
                <Button
                  variant="primary"
                  color="primary"
                  label={'Create New Listing'}
                  className="py-2 px-10 rounded-sm w-full md:mt-0 mt-4"
                  loading={isNewListingLoading}
                  onClick={() => createNewListingDuringSession(false)}
                />
              }
            >
              <div className="flex flex-col md:flex-row space-y-6 md:space-x-6 md:space-y-0 mb-6">
                <div className="w-full md:w-2/5">
                  <DragAndDrop
                    ref={dragAndDropRef}
                    onImageDescription={handleImageDescription}
                    onImageUpload={(flag: boolean) => notifyChatBot(flag)}
                    imgBase64={imgBase64}
                    disableAllButtons={disableAllButtons}
                    isDuplicate={isDuplicate}
                  />
                </div>
                <div className="w-full md:w-3/5">
                  <ChatComponent
                    ref={chatRef}
                    imageDescription={imageDescription}
                    isImageUploaded={isImageUploaded}
                    isImageError={isImageError}
                    handleChatEnd={handleChatEnd}
                    customInstructions={customInstructions}
                    setCustomInstructions={setCustomInstructions}
                    isChatBotDisabled={disableAllButtons || isTitleGenerated}
                    loadedChatMessages={loadedChatMessages}
                  />
                </div>
              </div>
              <div className="flex flex-col-reverse md:flex-row justify-end mt-6 md:space-x-3">
                {isLoading ? (
                  <CustomLoader width={120} />
                ) : new URLSearchParams(window.location.search).get(
                    'feedback'
                  ) === 'true' ? (
                  <Button
                    label="Regenerate Titles"
                    color="primary"
                    disabled={
                      !(
                        isFeedBackAllowed &&
                        new URLSearchParams(window.location.search).get(
                          'feedback'
                        ) === 'true'
                      ) || !isDuplicationAllowed
                    }
                    size="medium"
                    fullWidth
                    className={`${
                      isFeedBackAllowed &&
                      new URLSearchParams(window.location.search).get(
                        'feedback'
                      ) === 'true'
                        ? 'text-white'
                        : ''
                    } md:max-w-40 py-2 `}
                    onClick={e => {
                      fetchDuplicateSessionData(listingId || '', true);
                      const url = new URL(window.location.href);
                      url.searchParams.delete('feedback');
                      window.history.pushState({}, '', url.toString());
                      setReGenerateLoading(true);
                    }}
                  />
                ) : (
                  <Button
                    label={'Generate Titles'}
                    color="primary"
                    disabled={
                      !isKeywordTitleGenerateButtonEnabled ||
                      disableButton ||
                      disableAllButtons
                    }
                    size="medium"
                    fullWidth
                    className={`${
                      isKeywordTitleGenerateButtonEnabled ||
                      !disableButton ||
                      disableAllButtons
                        ? 'text-white'
                        : ''
                    } md:max-w-40 py-2 `}
                    onClick={e => {
                      load(30);
                      handleGenerateGeneration();
                    }}
                  />
                )}
              </div>

              {isLoading && (
                <div>{renderDisclaimer(titleLoadingDisclaimer)}</div>
              )}
            </Card>
          ) : (
            <Card
              heading="Add New Listing"
              subHeading="Talk with Chatbot or Upload Image to Generate the Title"
              className="md:h-[910px] items-center justify-center relative"
            >
              <div
                id={!isCheckingOut ? 'pendo-virus-007' : undefined}
                className="flex flex-col h-[80%] items-center justify-center space-y-3 "
              >
                <Icons.EmptyContainer className="mb-6" />
                <p className="tracking-[0.8px]">Nothing to show</p>
                <Button
                  variant="primary"
                  color="primary"
                  label={'Create New Listing'}
                  className="py-2 px-10 rounded-sm"
                  onClick={createNewListing}
                  loading={isNewListingLoading}
                />
                <div className="px-[40px] max-w-[500px]">
                  {renderDisclaimer(remainingListingsDisclaimer)}
                </div>
              </div>
            </Card>
          )}

          <div className="mt-8 items-center">
            <TitleGenerationCard
              isTitleGenerated={isTitleGenerated}
              generatedTitles={generatedTitles}
              notifyAltCard={handleAltCardGeneration}
              generatedTags={generatedAltTags}
              disableAllButtons={disableAllButtons}
              disableAltGenerationButton={disableAltGenerationButton}
              setSelectedTitleIndex={setSelectedTitleIndex}
              selectedTitleIndex={selectedTitleIndex}
            />
          </div>
          <div className="mt-8 items-center">
            <AltCard
              generatedAltText={generatedAltText}
              generatedAltDescription={generatedAltDescription}
              generatedAltTags={generatedAltTags}
              generatedAltCustomKeywords={generatedCustomKeywords}
              isAltCardGenerated={isAltCardGenerated}
              generatedData={generatedData}
              selectedTitleIndex={selectedTitleIndex}
            />
          </div>
          {isFeedBackAllowed && (
            <Card className="mt-8">
              <div className="flex items-center justify-center">
                {isDuplicationAllowed ? (
                  <span className="text-sm ">
                    If you'd like to make an adjustment to your listing,{' '}
                    <button
                      onClick={() => {
                        setIsFeedbackModalOpen(true);
                      }}
                      className="text-[#4AA3E2] hover:text-[#3886bf] underline cursor-pointer"
                    >
                      click here
                    </button>{' '}
                    to navigate back to the bot and type in your feedback
                  </span>
                ) : (
                  <span className="text-sm ">
                    You have exceeded the number of versions for this listing.
                    Please{' '}
                    <button
                      className="text-[#4AA3E2] hover:text-[#3886bf] underline cursor-pointer"
                      onClick={() => createNewListingDuringSession(false)}
                    >
                      create a new listing
                    </button>
                  </span>
                )}
              </div>
            </Card>
          )}

          {showScrollToBottomButton && (
            <div
              className="fixed bottom-60 right-6 !p-3 !min-w-0 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 bg-etsyPrimary hover:bg-[#2fa268] cursor-pointer w-12 h-12 flex items-center justify-center"
              onClick={scrollToTop}
            >
              <Icons.ChevronWhite />
            </div>
          )}
        </div>
      )}
      <ConfirmationModal
        isOpen={listingConsumedModalOpened}
        closeModal={() => setListingConsumedModalOpened(false)}
        onConfirm={() => {
          setPlansModalOpened(true);
          setListingConsumedModalOpened(false);
        }}
        onCancel={() => {
          setListingConsumedModalOpened(false);
        }}
        title="Please Purchase a Plan"
        description="You don't have any listings. Please continue with a subscription plan."
        confirmLabel="Purchase a Plan"
        cancelLabel="Skip"
        icon={'etSEOLogo.png'}
      />
      <ConfirmationModal
        isOpen={consumedTrialModalOpened}
        closeModal={() => setConsumedTrialModalOpened(false)}
        onCancel={() => {
          setConsumedTrialModalOpened(false);
        }}
        onConfirm={() => {
          setPlansModalOpened(true);
          setConsumedTrialModalOpened(false);
        }}
        title="Please Purchase a Plan"
        description="You have already consumed a Trial. Please continue with a  Subscription Plan"
        confirmLabel="Purchase a Plan"
        cancelLabel="Skip"
        icon={'etSEOLogo.png'}
      />
      <ConfirmationModal
        isOpen={purchaseTrialModalOpened}
        closeModal={() => {
          setPurchaseTrialModalOpened(false);
        }}
        onCancel={() => {
          setPlansModalOpened(true);
          setPurchaseTrialModalOpened(false);
        }}
        onConfirm={() => {
          handleStartTrial();
          setPurchaseTrialModalOpened(false);
        }}
        title="Start a Trial for only $1"
        description="Experience access to more features for a limited time. You’ll be able to have 1 listing generation"
        confirmLabel="Start $1 Trial"
        cancelLabel="Purchase a Plan"
        icon={'etSEOLogo.png'}
      />
      <ConfirmationModal
        isOpen={listingNotCompleteModal}
        closeModal={() => {
          setListingNotCompleteModal(false);
        }}
        onCancel={() => {
          setListingNotCompleteModal(false);
        }}
        onConfirm={() => {
          setListingNotCompleteModal(false);
          setBypassIncompleteListingCheck(true);
          createNewListingDuringSession(true);
        }}
        title="It looks like this listing isn't fully completed yet. "
        description="Are you sure you want to start a new one? Don't worry, you can always find this listing later in your History."
        confirmLabel="Create New"
        cancelLabel="Cancel"
        icon={'etSEOLogo.png'}
      />
      <ConfirmationModal
        isOpen={isFeedbackModalOpen}
        closeModal={() => {
          setIsFeedbackModalOpen(false);
        }}
        onCancel={() => {
          setIsFeedbackModalOpen(false);
        }}
        onConfirm={() => {
          setIsFeedbackModalOpen(false);
          handleFeedback();
        }}
        title="Send Feedback"
        description="Are you sure you want to type in your feedback?"
        confirmLabel="Yes"
        cancelLabel="Cancel"
        icon={'etSEOLogo.png'}
      />
      <BillingPlanModal
        isOpen={plansModalOpened}
        onClose={() => setPlansModalOpened(false)}
        isModal={true}
      />
    </>
  );
};

export default Home;
