import React from 'react';
import Modal from '../../../components/shared/ui/Modal';
import { Button } from '../../../components/shared/ui/Button';

interface ConfirmationModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
  title?: string;
  description?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  icon?: string;
  showInput?: boolean;
  inputProps?: {
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
    label?: string;
    error?: string;
  };
  cancelLoading?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  closeModal,
  onConfirm,
  isLoading,
  onCancel,
  title = 'Are you sure?',
  description = 'Do you want to proceed with this action?',
  confirmLabel = 'Yes',
  cancelLabel = 'No',
  icon = '',
  showInput = false,
  inputProps,
  cancelLoading = false,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      className="w-[calc(100%-48px)] max-w-md p-6 pt-8 md:p-8 overflow-auto max-h-screen"
    >
      <div className="text-center">
        {icon && (
          <div className="flex justify-center mb-4">
            <img src={icon} alt="Confirmation Icon" className="w-16 h-16" />
          </div>
        )}
        <h2 className="font-bold text-lg md:font-semibold md:text-xl mb-4">
          {title}
        </h2>
        <p className="text-sm md:text-base text-[#4E4E4E] mb-6">
          {description}
        </p>

        {showInput && inputProps && (
          <div className="mb-6 text-left">
            {inputProps.label && (
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {inputProps.label}
              </label>
            )}
            <input
              type="text"
              value={inputProps.value}
              onChange={e => inputProps.onChange(e.target.value)}
              placeholder={inputProps.placeholder}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-etsyPrimary focus:border-etsyPrimary"
            />
            {inputProps.error && (
              <p className="mt-1 text-sm text-[#FF0000]">{inputProps.error}</p>
            )}
          </div>
        )}

        <div className="flex flex-col md:flex-row justify-center gap-4 mt-9">
          <Button
            label={cancelLabel}
            onClick={onCancel}
            className="px-4 py-2 rounded-lg font-bold text-lg border border-etsyPrimary text-etsyPrimary w-full bg-white cursor-pointer"
            loading={cancelLoading}
          />
          <Button
            label={confirmLabel}
            onClick={onConfirm}
            className="px-4 py-2 rounded-lg font-bold text-lg border border-etsyPrimary bg-etsyPrimary text-white w-full cursor-pointer"
            loading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
