/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Modal from '../../shared/ui/Modal';
import { Icons } from '../../../helpers/icons';
import { axiosStripeInstance } from '../../../api/apiInstances';
import useGenericGetQuery from '../../../CustomHooks/useQuery';
import useGenericMutation from '../../../CustomHooks/useMutation';
import {
  BasicPlan,
  CheckoutSessionResponse,
  MyStripeSubscription,
} from '../../../common/types/stripeSessions';
import Loader from '../../shared/layout/Loader';
import { useClerk } from '@clerk/clerk-react';
import { Button } from '../../shared/ui/Button';
import { useAppDispatch } from '../../../helpers/hooks';
import {
  setStripeData,
  setStripeSessionId,
} from '../../../redux/feature/StripeSlice';
import { InputField } from '../../shared/ui/InputField';
import useAlert from '../../../CustomHooks/useAlert';
import Alert from '../../shared/ui/Alert';
import { ALERT_MESSAGES } from '../../../constants';
import TagManager from 'react-gtm-module';
import ConfirmationModal from '../../../container/AccountDetails/ConfirmationModal';

interface BillingPlanModalProps {
  availablePlans?: MyStripeSubscription;
  isModal?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
}

const buttonLabels = [
  'Continue with Small',
  'Continue with Medium',
  'Continue with Large',
  'Continue with X-Large',
  'Continue with Custom',
];

const BillingPlanModal = ({
  availablePlans,
  isModal = false,
  isOpen = false,
  onClose,
}: BillingPlanModalProps) => {
  const { user } = useClerk();
  const userId = user?.id;
  const [fetchEndpoint, setFetchEndpoint] = useState<string | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [hasCurrentPlan, setHasCurrentPlan] = useState<boolean>(false);
  const [currentBasicPlan, setCurrentBasicPlan] = useState<BasicPlan>();
  const dispatch = useAppDispatch();
  const [customListings, setCustomListings] = useState<string>('');
  const [customPrice, setCustomPrice] = useState<number>(0);
  const [customListingsError, setCustomListingsError] = useState<string>('');
  const [promotionModal, setPromotionModal] = useState<boolean>(false);
  const [planId, setPlanId] = useState<string | null>(null);
  const [price, setPrice] = useState<number>(0);
  const [listings, setListings] = useState<number>(0);
  const { alert, setAlert, closeAlert } = useAlert();
  const [promoCode, setPromoCode] = useState('');
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [oneFreeListingConsumed, setOneFreeListingConsumed] =
    useState<boolean>(false);

  const {
    data: fetchedPlans,
    isLoading,
    refetch,
  } = useGenericGetQuery<MyStripeSubscription>(
    fetchEndpoint ? fetchEndpoint : '',
    axiosStripeInstance,
    { userId }
  );

  const { mutateAsync: createCheckoutSession } =
    useGenericMutation<CheckoutSessionResponse>(
      '/subscription/create-checkout-session',
      'POST',
      axiosStripeInstance
    );

  const { mutateAsync: updateUserProfile } = useGenericMutation<any>(
    '/users/profile',
    'PUT',
    axiosStripeInstance
  );

  useEffect(() => {
    if (isModal) {
      setFetchEndpoint('/subscription/all-subscriptions');
    }
  }, []);

  const effectivePlans = isModal ? fetchedPlans : availablePlans;

  const [filteredPlans, setFilteredPlans] = useState<MyStripeSubscription>();

  useEffect(() => {
    if (effectivePlans) {
      const filteredPlans = {
        ...effectivePlans,
        basicPlans: effectivePlans.basicPlans.filter(
          plan => plan.tierType !== 'trial' && plan.tierType !== 'custom'
        ),
      };
      setFilteredPlans(filteredPlans);
      const filteredCustomPlan = effectivePlans.basicPlans.filter(
        plan => plan.tierType === 'custom'
      );

      if (filteredCustomPlan.length > 0) {
        setHasCurrentPlan(true);
        setCurrentBasicPlan(filteredCustomPlan[0]);
        return;
      }

      const currentPlan = filteredPlans.basicPlans.find(
        plan =>
          plan.currentPlan ||
          plan.status == 'active' ||
          plan.status == 'expired'
      );
      setCurrentBasicPlan(currentPlan);
      if (currentPlan) {
        setSelectedPlan(currentPlan.id);
        setHasCurrentPlan(true);
      } else {
        setHasCurrentPlan(false);
      }
    }
  }, [effectivePlans]);

  const [loadingState, setLoadingState] = useState<boolean>(false);

  const handleContinue = async (
    planId: string,
    price: number,
    listings: number,
    promoCodeId?: string
  ) => {
    const params = new URLSearchParams();
    params.set('fromStripe', 'true');
    params.set('trial', 'false');
    params.set('userId', userId || '');

    const encodedParams = btoa(params.toString());
    const successPath = `/stripe-success?params=${encodedParams}&price=${price}`;
    const cancelPath = `/stripe-cancel?params=${encodedParams}`;

    if (planId === 'custom' && !customListings) {
      setCustomListingsError('Please enter the number of listings');
      setCancelLoading(false);
      setLoadingState(false);
      return;
    }

    if (hasCurrentPlan) {
      try {
        await updateUserProfile({
          lastRemainingListings: currentBasicPlan?.remainingListings,
        });
      } catch (error: any) {
        const message = error.response?.data?.message;
        setAlert({
          isSuccess: false,
          message: message || 'Error updating user profile',
        });
      }
    }

    try {
      const response = await createCheckoutSession({
        planId,
        userId,
        successUrl: `${window.location.origin}${successPath}`,
        cancelUrl: `${window.location.origin}${cancelPath}`,
        planType: planId === 'custom' ? 'custom' : 'basic',
        customListings: planId === 'custom' ? customListings : undefined,
        promoCodeId: promoCodeId || undefined,
      });
      dispatch(setStripeSessionId(response.id));

      TagManager.dataLayer({
        dataLayer: {
          event: 'checkout_start',
          checkout_session_id: response.id,
          plan_id: planId,
          listing_count: listings,
          price: price,
        },
      });
      if (response?.message?.includes('consume your trial listings')) {
        setAlert({
          isSuccess: false,
          message: ALERT_MESSAGES.CONSUME_TRIAL_LISTINGS,
        });
        return;
      }
      dispatch(
        setStripeData({ stripeUrl: response.paymentUrl, selectedPlan: planId })
      );
      window.open(response.paymentUrl, '_blank');
      setCustomListings('');
      if (window.fbq) {
        const numericPrice = Number(price);

        window.fbq('track', 'InitiateCheckout', {
          content_type: 'product',
          content_ids: [planId],
          external_id: userId,
          contents: [
            {
              id: planId,
              quantity: listings,
            },
          ],
          currency: 'USD',
          num_items: listings,
          value: numericPrice,
        });
      }
    } catch (error: any) {
      const message = error.response?.data?.message;
      setAlert({
        isSuccess: false,
        message: message || 'Error creating checkout session',
      });
    } finally {
      setLoadingState(false);
      setPromotionModal(false);
      setCancelLoading(false);
      setError('');
    }
  };

  const handleApplyPromoCode = async () => {
    const response = await axiosStripeInstance.get(
      `/subscription/verify-promo-code?promoCode=${promoCode}&planId=${planId}&userId=${userId}&totalListings=${listings}`
    );
    if (response.data.valid) {
      setAlert({
        isSuccess: true,
        message: 'Promo code applied successfully',
      });
      handleContinue(planId!, price, listings, response.data.promoCodeId);
    } else {
      setLoadingState(false);
      setCancelLoading(false);
      setError(response.data.message);
    }
  };

  const handleCloseModal = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'checkout_cancelled',
      },
    });
    setSelectedPlan(null);
    onClose?.();
  };

  const calculateCustomPrice = (listings: number) => {
    if (listings < 10) return 4.99;
    if (listings < 20) return 4.99;
    if (listings < 50) return 4.49;
    if (listings < 200) return 4.25;
    return 3.99;
  };

  const handleCustomListingsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setCustomListings(value);
    setCustomListingsError('');

    const numValue = parseInt(value);
    if (!isNaN(numValue)) {
      const minListings = oneFreeListingConsumed ? 10 : 1;
      if (numValue < minListings) {
        setCustomListingsError(
          `Please enter a value of ${minListings} or greater`
        );
      } else if (numValue > 100000000) {
        setCustomListingsError('Please enter a value of 100,000,000 or less');
      } else {
        const price = calculateCustomPrice(numValue);
        setCustomPrice(price * numValue);
      }
    } else {
      setCustomPrice(0);
    }
  };

  const handleCustomListingsBlur = () => {
    if (customListings !== '') {
      const minListings = oneFreeListingConsumed ? 10 : 1;
      if (parseInt(customListings) < minListings) {
        setCustomListings(minListings.toString());
        setCustomListingsError('');
        const price = calculateCustomPrice(minListings);
        setCustomPrice(price * minListings);
      }
    }
  };

  useEffect(() => {
    if (customListings === '') {
      setCustomPrice(0);
    } else {
      const listingsCount = Number(customListings);
      const price = calculateCustomPrice(listingsCount);
      setCustomPrice(price * listingsCount);
    }
  }, [customListings]);

  useEffect(() => {
    const fetchOneFreeListingConsumed = async () => {
      if (userId) {
        try {
          const response = await axiosStripeInstance.get<any>(
            `/users/${userId}`
          );
          setOneFreeListingConsumed(
            response.data.oneFreeListingConsumed || false
          );
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };

    fetchOneFreeListingConsumed();
  }, [userId]);

  const content = (
    <>
      {alert && (
        <Alert
          isSuccess={alert.isSuccess}
          message={alert.message}
          onClose={closeAlert}
        />
      )}
      {isModal && (
        <div className="pb-6 mb-8 border-b">
          <div className="flex flex-row justify-between w-full">
            <div />
            <p className="font-semibold text-2xl mb-2">Choose a bundle</p>

            <div className="cursor-pointer" onClick={handleCloseModal}>
              <Icons.Cross />
            </div>
          </div>
          <p className="text-center font-normal text:sm md:text-base text-[#4E4E4E]">
            Pick a bundle to grow your Etsy business with etSEO
          </p>
        </div>
      )}

      <div className="mb-10 flex flex-wrap justify-center gap-6 overflow-auto text-[#828282]">
        {isLoading && <Loader />}
        {filteredPlans?.basicPlans.map((plan, index) => {
          return (
            <div
              key={plan.id}
              className={`shadow-[0px_0px_12px_0px_rgba(74,131,184,0.12)] bg-[#F3EDE9] rounded-lg flex flex-col
                p-6 pr-8 justify-between cursor-pointer w-full  md:w-[400px] flex-wrap ${
                  plan.id === selectedPlan
                    ? 'border-etsyPrimary border'
                    : 'border-1'
                }`}
            >
              <div className="flex flex-col space-evenly">
                <div className=" w-full flex flex-col justify-center text-black">
                  <p className={`md:text-3xl  text-lg font-semibold`}>
                    {plan.title}
                    {plan.currentPlan && (
                      <span className="md:text-base text-sm font-normal pl-3">
                        (Active Plan)
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <div>
                <div className="mt-3 text-sm md:text-base font-normal self-center">
                  {plan.description}
                </div>
                <p className="mt-12 text-etsyPrimary font-bold">
                  {plan.featuresHeading}
                </p>
                <div className="text-xl mt-12 mb-5 md:text-3xl text-black">
                  <p className="md:text-4xl text-xl font-bold ">
                    ${(plan.price / plan.basicPlanListings!).toFixed(2)}
                  </p>
                  <p className="text-sm md:text-base text-[#828282] ">
                    per listing
                  </p>
                </div>

                <div className="h-[60px] flex items-center justify-center">
                  <Button
                    label={buttonLabels[index] || 'Continue'}
                    onClick={() => {
                      setPromotionModal(true);
                      setPromoCode('');
                      setPlanId(plan.id);
                      setPrice(plan.price);
                      setListings(plan.basicPlanListings!);
                    }}
                    // loading={loadingState[plan.id]}
                    color="primary"
                    className="px-6 py-3 rounded-lg font-bold text-lg text-white w-full h-full"
                    loaderStroke="text-white"
                  />
                </div>
                <div className="text-sm md:text-base text-[#716B6A] font-bold text-center mt-2">
                  {plan.basicPlanListings} listings total (${plan.price})
                </div>
              </div>
            </div>
          );
        })}

        <div className="shadow-[0px_0px_12px_0px_rgba(74,131,184,0.12)] bg-[#F3EDE9] rounded-lg flex flex-col p-6 pr-8 gap-1 cursor-pointer  w-full  md:w-[400px]">
          <div className="w-full flex flex-col justify-center text-black">
            <p className="md:text-3xl text-black text-lg font-semibold">
              Custom Bundle
            </p>
          </div>
          <div className="mt-3 text-sm md:text-base font-normal">
            <p>10-20 listings: $4.99 per listing</p>
            <p>21-50 listings: $4.49 per listing</p>
            <p>51-200 listings: $4.25 per listing</p>
            <p>Over 201 listings: $3.99 per listing</p>
          </div>
          <div className="mt-2">
            <InputField
              name="customListings"
              placeholder="Enter amount of listings"
              value={customListings}
              onChange={handleCustomListingsChange}
              onBlur={handleCustomListingsBlur}
              inputContainerClass=" !border-etsyPrimary"
              type="number"
              min={oneFreeListingConsumed ? 10 : 1}
              max={100000000}
              errorMsg={customListingsError}
            />
          </div>
          <div className="text-xl mt-3 mb-5  md:text-3xl text-black">
            <p className="md:text-4xl text-xl font-bold">
              ${customPrice.toFixed(2)}
            </p>
          </div>
          <div className="h-[60px] mt-2 flex items-center justify-center">
            <Button
              label={buttonLabels[4]}
              onClick={() => {
                setPromotionModal(true);
                setPromoCode('');
                setPlanId('custom');
                setPrice(customPrice);
                setListings(Number(customListings));
              }}
              color="primary"
              className="px-6 py-3 rounded-lg font-bold text-lg text-white w-full h-full "
              loaderStroke="text-white"
            />
          </div>
        </div>
      </div>

      {isModal && (
        <div className="flex justify-end w-full gap-4">
          <button
            onClick={handleCloseModal}
            className="border-[1px] px-6 py-3 rounded-lg border-etsyPrimary font-semibold text-lg text-etsyPrimary min-w-[190px]"
          >
            Cancel
          </button>
        </div>
      )}
      <ConfirmationModal
        isOpen={promotionModal}
        closeModal={() => {
          setPromotionModal(false);
          setError('');
        }}
        title="Promo Code"
        description="Do you have a promo code? Enter it and click 'Apply' to use it. If you don't have a code, click 'Continue' to proceed without one."
        onConfirm={() => {
          if (promoCode) {
            setLoadingState(true);
            handleApplyPromoCode();
          } else {
            setError('Please enter a promo code or continue');
          }
        }}
        onCancel={() => {
          setCancelLoading(true);
          handleContinue(planId!, price, listings, undefined);
        }}
        confirmLabel="Apply"
        cancelLabel="Continue"
        icon={'etSEOLogo.png'}
        showInput={true}
        inputProps={{
          placeholder: 'Enter your promo code',
          value: promoCode,
          onChange: (value: string) => {
            setPromoCode(value);
            setError('');
          },
          error: error,
        }}
        isLoading={loadingState}
        cancelLoading={cancelLoading}
      />
    </>
  );

  return isModal ? (
    <Modal
      isOpen={isOpen}
      closeModal={handleCloseModal}
      className="w-[calc(100%-50px)] items-center p-6 pt-8 md:p-8 overflow-auto max-h-screen"
    >
      {content}
    </Modal>
  ) : (
    content
  );
};

export default BillingPlanModal;
